import Layout from '../components/layout'
import "../styles/styles.scss"
import { StaticImage } from 'gatsby-plugin-image'
import React, { useState, useEffect } from 'react';
import { sectionDescriptions } from '../assets/content';
import Contact from '../components/contact';

// style
const appStyle = {
  margin: 0
}

// markup
const IndexPage = () => {

  return (
    <div style={appStyle}>
      <Layout pageTitle="Home Page">
        <div className="container header" id="home">
          <div className="titleContainer">
            <StaticImage
              className='headerLogo'
              alt="Lecsa Logo"
              src="../images/lecsafreight_logo.png"
            />
            <div className="title">
              <h1>Lecsa Freight</h1>
              <p>Comprehensive Transport Logistics</p>
            </div>
          </div>
        </div>
        <div className="container section whiteBg" id="ground">
          <div className="sectionTitleRow">
            <h1>Ground Transportation</h1>
            <div className="sectionTitleDivider"></div>
            <div className="description">{sectionDescriptions.groundTransportation}</div>
          </div>
          <div className="cardDeck">
            <div className="card">
              <div className="cardTitle">Less than a truck load</div>
              <div className="cardDivider"></div>
              <ul className="cardList">
                <li>Consolidated freight</li>
                <li>Partial load in flatbeds</li>
                <li>Hot-shot service</li>
                <li>Dedicated service on any type of vehicle, from pickup truck to full trailer.</li>
              </ul>
            </div>
            <div className="card">
              <div className="cardTitle">Full truck load</div>
              <div className="cardDivider"></div>
              <ul className="cardList">
                <li>Flatbed 48’ and 53’</li>
                <li>Dry van 48’ and 53’</li>
                <li>Refrigerated van</li>
                <li>Low boy</li>
                <li>Extendible flatbed</li>
                <li>Conestoga</li>
                <li>Tanker truck</li>
              </ul>
            </div>
            <div className="card">
              <div className="cardTitle">Project cargo</div>
              <div className="cardDivider"></div>
              <ul className="cardList">
                <li>Multimodal transportation</li>
                <li>Specialized equipment for oversize / overweight pieces</li>
                <li>Hazmat</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container section oceanBg" id="ocean">
          <div className="sectionTitleRow">
            <h1>Ocean Transportation</h1>
            <div className="sectionTitleDivider"></div>
            <div className="description">{sectionDescriptions.oceanTransportation}</div>
          </div>
          <div className="cardDeck">
          <div className="card">
              {/* <div className="cardTitle">Project cargo</div> */}
              <ul className="cardList">
                <li>Full Container Load (FCL)</li>
                <li>Less than Container Load (LCL)</li>
                <li>Break bulk cargo</li>
                <li>Chartered vessels</li>
                <li>Barge</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container section whiteBg" id="aerial">
          <div className="sectionTitleRow">
            <h1>Aerial Transportation</h1>
            <div className="sectionTitleDivider"></div>
            <div className="description">{sectionDescriptions.aerialTransportation}</div>
          </div>
          <div className="cardDeck">
          <div className="card">
              {/* <div className="cardTitle">Project cargo</div> */}
              <ul className="cardList">
                <li>Air cargo worldwide</li>
                <li>Hand carry delivery</li>
                <li>Chartered aircraft</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container section oceanBg" id="additional">
          <div className="sectionTitleRow">
            <h1>Additional Services</h1>
            <div className="sectionTitleDivider"></div>
            <div className="description">{sectionDescriptions.additionalServices}</div>
          </div>
          <div className="cardDeck">
          <div className="card">
              <ul className="cardList">
                <li>Cargo Insurance</li>
                <li>Customs borkerage</li>
                <li>Warehousing and Storage</li>
                <li>Pick & pack and Distribution</li>
                <li>Inspection in origin</li>
                <li>Expert packaging consulting</li>
                <li>Packing supplies</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container section whiteBg" id="packaging">
          <div className="sectionTitleRow">
            <h1>Packaging Consulting</h1>
            <div className="sectionTitleDivider"></div>
            <div className="description">{sectionDescriptions.packagingConsulting}</div>
          </div>
          <div className="cardDeck">
          <div className="card">
              <ul className="cardList">
                <li>Our consultants have the ISTA certification, ensuring our knowledge and professionalism in the area of ​​packaging.</li>
                <li>Easily identify your weaknesses and capitalize on significant savings for your organization.</li>
                <li>Our priority is to protect the integrity of your product from origin to final destination at the lowest possible cost.</li>
              </ul>
            </div>
          </div>
        </div>
        <Contact />
      </Layout>
    </div>
  )
}

export default IndexPage
