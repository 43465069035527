import React, { useState } from 'react';
import "../styles/styles.scss"
import FlashMessage from 'react-flash-message';
import emailjs from "emailjs-com";

const Contact = () => {
    const [showMessage, setShowMessage] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [alert, setAlert] = useState("");
  
    const validEmail = () => {
      let valid = false;
      if(name != "", email != "", subject != "", message != ""){
        valid = true;
      }
      return valid;
    }
    
    const sendEmail = (event) => {
      event.preventDefault();
      setShowMessage(false);
      if(validEmail){
        console.log(event.target)
        emailjs.sendForm(
          "service_y1hmmxa",
          "template_rbmo4t8",
          event.target,
          "user_M0llkcAh1R2gKt2pzfkIk"
        ).then(
          result => {
            console.log(result.text)
            setShowMessage(true);
            console.log(showMessage);
            setAlert("Thanks for your message! We'll get back to you soon.");
            console.log(alert);
            setName("");
            setEmail("");
            setSubject("");
            setMessage("");
          },
          error => {
            console.log(error.text)
          }
        )
      } else {
        setShowMessage(true);
        setAlert("Please fill all the fields");
      }
    }

    return(
        <div className="container section contactUs" id="contact">
            <div className="card">
              <div className='contactLeft'>
                <h1>Contact</h1>
                <p>
                  If you have any questions regarding our company and services, 
                  please do not hesitate to contact  us using the form.
                </p>
              </div>
              <form className="contactForm" onSubmit={sendEmail}>
                <div className="formElement">
                  <div className="formLabel">Name</div>
                  <input onChange={(event) => setName(event.target.value)} value={name} type="text" className="formInput" name="name" required/>
                </div>
                <div className="formElement">
                  <div className="formLabel">Email Address</div>
                  <input onChange={(event) => setEmail(event.target.value)} value={email} type="text" className="formInput" name="email" type="email" required/>
                </div>
                <div className="formElement">
                  <div className="formLabel">Subject</div>
                  <input onChange={(event) => setSubject(event.target.value)} value={subject} type="text" className="formInput" name="subject" required/>
                </div>
                <div className="formElement">
                  <div className="formLabel">Message</div>
                  <textarea onChange={(event) => setMessage(event.target.value)} value={message} type="text" className="formInput" name="message" required/>
                </div>
                <input type="submit" className='formSubmit'/>
                {showMessage &&
                  <div style={{marginTop: "20px"}}>
                    <FlashMessage duration={4000} persistOnHover={true}>
                      <strong>{alert}</strong>
                    </FlashMessage>
                  </div>
                }
              </form>
            </div>
        </div>
    )
}

export default Contact